/* 所有在top位置的toast提示 高度减少60 */
div[class*="Toastify__toast-container--top"] {
  top: 60px;
}
/* 所有在bottom位置的toast提示 高度增加200 */
div[class*="Toastify__toast-container--bottom"] {
  bottom: 200px;
}
/* 在顶部中央的toast提示 高度高一些 */
.Toastify__toast-container--top-center {
  top: 2px !important;
}
/* 在toast容器设置的类 */
.toastContainerClass {
  padding: 4px;
}

/* 带有滚动条 可识别换行  需要一个高度*/
.textReader {
  white-space: pre-wrap;
  overflow-y: scroll;
  overflow-x: auto;
  resize: both;
  /* height: 350px; */
}

/* tooltip提示 替代title */
.shToolTip:hover:after {
  /* content: attr(shtitle); */
  content: attr(shtitle);
  position: absolute;
  display: inline-block;
  top: -2rem;
  padding: 5px;
  border: 1px solid gray;
  color: black;
  z-index: 999999;
  white-space: nowrap;
  background: whitesmoke;
  font-weight: 400;
  font-size: 15px;
}

/* 动画  animation: scrollToRowAnimation 5s; */
/* @keyframes scrollToRowAnimation {
  0% {
    background: #02c0ff;
  }
  25% {
    background: #04e8e8;
  }
} */

/* 扩散动画 */
@keyframes ripple {
  100% {
    transform: scale(3);
    opacity: 0;
  }
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
